<template>
  <div class="home">
    <div class="login">
      <img class="logo" src="../assets/images/logo.png" alt="">
      <div class="title">关于软件</div>
      <div class="vision"></div>
      <div class="line"><span>产品名称：</span>眼科OCT影像辅助诊断软件</div>
      <div class="line"><span>产品型号：</span>MIAS-3000</div>
      <div class="line"><span>发布版本：</span>V1</div>
      <div class="line"><span>完整版本：</span>V1.0.0.0</div>
      <div class="line"><span>公司名称：</span>苏州比格威医疗科技有限公司</div>
      <div class="line"><span>公司住所：</span>江苏省苏州市高新区竹园路209号</div>
      <div class="line"><span>联系方式：</span>0512-68136727</div>

    </div>
    <div class="back"><img src="../assets/images/Back.png" alt="" @click="goBack"></div>
  </div>
</template>

<script>
export default {
  name: "Explain",
  methods: {
    goBack() {
      this.$router.go(-1)
    }
  }
};
</script>
<style lang="scss" scoped>
.home {
  width: 100vw;
  height: 100vh;
  background-image: url('../assets/images/Login_bg.png');
  background-size: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.login {
  width: 432px;
  height: 538px;
  background-color: #fff;
  //   opacity: 0.6;
  padding: 0 22px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 12px 20px rgba(38, 38, 38, 0.12);
  border-radius: 12px;
  box-sizing: border-box;
}
.title {
  width: 100%;
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  margin-top: 10px;
  color: #333;
}
.logo {
  margin-top: 40px;
  margin-left: 120px;
}
.vision {
  width: 100%;
  text-align: center;
  font-size: 15px;
  margin-top: 20px;
  margin-bottom: 40px;
  color: #999;
  font-weight: 600;
}
.line {
  width: 100%;
  color: #333;
  margin-bottom: 15px;
  height: 38px;
  line-height: 38px;
  border-bottom: 1px solid #f8f8f8;
  span{
    display: inline-block;
    width:110px;
    text-align: left;
    font-weight: 600;
  }
}
.back {
  position: absolute;
  left: 30px;
  top: 20px;
}
.back img {
  width: 32px;
  height: 32px;
  cursor: pointer;
}
.back img:hover {
  transform: scale(1.1);
}
</style>
